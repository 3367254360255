var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-user-container"},[_c('div',{staticClass:"panel-header"},[_c('div',{staticClass:"top-header"},[_c('div',[_c('v-btn',{staticStyle:{"background-color":"#522e8e","color":"#fff"},attrs:{"large":""}},[_vm._v(" Active Teams ")])],1),_c('div',{staticClass:"top-right"},[_c('v-btn',{staticStyle:{"background-color":"#522e8e","color":"#fff"},attrs:{"small":""}},[_vm._v(" Add New Team ")]),_c('v-btn',{staticStyle:{"background-color":"rgb(61 185 156)","color":"#fff"},attrs:{"small":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" md-minus ")]),_vm._v(" Suspended Teams ")],1)],1)]),_c('div',{staticClass:"bottom-header"},[_c('v-tabs',[_c('v-tab',[_c('v-badge',{attrs:{"color":"red","content":"6"}},[_vm._v("All")])],1),_c('v-tab',[_c('v-badge',{attrs:{"color":"red","content":"6"}},[_vm._v("Candidate")])],1),_c('v-tab',[_c('v-badge',{attrs:{"color":"red","content":"6"}},[_vm._v("Representative")])],1),_c('v-tab',[_c('v-badge',{attrs:{"color":"red","content":"6"}},[_vm._v("Rep.to Cand.")])],1),_c('v-tab',[_c('v-badge',{attrs:{"color":"red","content":"6"}},[_vm._v("Matchmaker")])],1)],1),_c('v-text-field',{attrs:{"filled":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('div',{staticClass:"panel-content"},[_c('v-data-table',{staticClass:"dt-table",attrs:{"show-select":"","items":_vm.items,"headers":_vm.headers,"single-select":false,"search":_vm.search,"item-key":"name","footer-props":{
        'items-per-page-text': 'Show',
      }},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.value},[(header.text !== 'actions')?_c('span',[_vm._v(" "+_vm._s(header.text))]):_vm._e(),(header.text == 'actions')?_c('span',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(header.text)+" ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v("item 1")])],1)],1)],1)],1):_vm._e()])}),0)]}},{key:"body",fn:function(ref){
      var items = ref.items;
return _vm._l((items),function(item){return _c('tr',{key:item.name,on:{"click":function (e) { return _vm.onItemClick(item); }}},[_c('td',[_c('v-checkbox',{staticStyle:{"margin":"0px","padding-left":"16px"},attrs:{"value":item,"hide-details":""},model:{value:(_vm.selectedTasks),callback:function ($$v) {_vm.selectedTasks=$$v},expression:"selectedTasks"}})],1),_c('td',{staticClass:"id"},[_vm._v(_vm._s(item["id"]))]),_c('td',{staticClass:"created_at"},[_vm._v(_vm._s(_vm._f("formatDate")(item["created_at"])))]),_c('td',{staticClass:"name"},[_vm._v(_vm._s(item["name"]))]),_c('td',{staticClass:"description"},[_vm._v(_vm._s(item["description"]))]),_c('td',{staticClass:"publisher"},[_c('a',{attrs:{"href":'https://www.ncbi.nlm.nih.gov/pubmed/' + item.pmid,"target":"_blank"}},[_vm._v("Yes")])]),_c('td',{staticClass:"publisher"},[_c('a',{attrs:{"href":'https://www.ncbi.nlm.nih.gov/pubmed/' + item.pmid,"target":"_blank"}},[_vm._v("Yes")])]),_c('td',{staticClass:"Actions"},[_c('div',[_c('v-btn',{staticStyle:{"background-color":"#522e8e","color":"#fff"},attrs:{"small":""}},[_vm._v(" view ")]),_c('v-btn',{staticStyle:{"background-color":"rgb(42 205 100)","color":"#fff"},attrs:{"small":""}},[_vm._v(" Edit ")]),_c('v-btn',{staticStyle:{"background-color":"rgb(61 185 156)","color":"#fff"},attrs:{"small":""}},[_vm._v(" Suspend ")]),_c('v-btn',{staticStyle:{"background-color":"rgb(191 20 67)","color":"#fff"},attrs:{"small":""}},[_vm._v(" Note ")])],1)])])})}}]),model:{value:(_vm.selectedTasks),callback:function ($$v) {_vm.selectedTasks=$$v},expression:"selectedTasks"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }